import { graphql } from 'gatsby';
import React from 'react';
import Slider from 'react-slick';
import Heimdall from '../src/components/HeimdallHome/heimdallHome';
import Hod from '../src/components/Hod/Hod';
import H3 from '../src/components/Titles/TitleH3/TitleH3';
import Ymir from '../src/components/Ymir/Ymir';
import { YmirHeader } from '../src/components/Titles/YmirHeader/YmirHeaderv2';
import SEO from '../src/helpers/seo';
import tt from '../src/helpers/translation';
import Layout from '../src/components/Layout/Layout';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import NovedadesCards from '../src/components/NovedadesCards/NovedadesCards';
import './novedades.scss';

export const GatsbyQuery = ({ data, pathContext: { locale } }) => graphql`
  query novedades($locale: String!) {
    allNovedadesRichData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            og_title
            rich_markup_type
            og_description
            imgRich
          }
        }
      }
    }
    allNovedadesSeoHardcoded(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          richmark_text
        }
      }
    }
    allNovedadesBreadcrumbBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          name
        }
      }
    }
    allNovedadesSeoData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allNovedadesImageHeader(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          alt
          image
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allNovedadesTitleBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          description
        }
      }
    }
    allNovedadesImageMobileBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          alt
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allNovedadesCardBlock(
      filter: { lang: { eq: $locale } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          cardTitle
          activated_desciption
          cta
          cta_text
          card_size
          position
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          localImage2 {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt
        }
      }
    }
    allNovedadesCardBlockTitle(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
        }
      }
    }
    allNovedadesInformativeBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          content
        }
      }
    }
  }
`;

class Novedades extends React.Component {
  cardMap = (array) => {
    if (array.length) {
      return array.map((element) => <NovedadesCards data={element} key={element.position} />);
    }
    return null;
  };

  render() {
    const ymirData = {
      title: this.props.data.allNovedadesInformativeBlock.edges[0].node.title,
      subtitle: this.props.data.allNovedadesInformativeBlock.edges[0].node.content,
      cards: [
        {
          title: tt('GRUPO ESCOLAR (4-17 AÑOS)', this.props.pageContext.locale),
          buttonCard: tt('DESCUBRIR', this.props.pageContext.locale),
          link: tt('grupos/grupos-escolares', this.props.pageContext.locale),
          icon: 'grupos1',
        },
        {
          title: tt('GRUPO VACACIONAL', this.props.pageContext.locale),
          buttonCard: tt('DESCUBRIR', this.props.pageContext.locale),
          link: tt('/grupos/grupos-vacacionales', this.props.pageContext.locale),
          icon: 'grupos2',
        },
        {
          title: tt('FAMILIAS NUMEROSAS', this.props.pageContext.locale),
          buttonCard: tt('DESCUBRIR', this.props.pageContext.locale),
          link: tt('/entradas/familia-numerosa', this.props.pageContext.locale),
          icon: 'grupos3',
        },
      ],
    };

    const h1Data = {
      title: this.props.data.allNovedadesCardBlockTitle.edges[0].node.title.toUpperCase(),
    };

    const NovedadesData = {
      cards: this.props.data.allNovedadesCardBlock.edges.map((card) => ({
        image: card.node.localImage.childImageSharp.fluid,
        imageMobile: card.node.localImage2 ? card.node.localImage2.childImageSharp.fluid : '',
        altImage: card.node.alt,
        CardSize: card.node.card_size,
        position: card.node.position,
        text1: card.node.activated_desciption,
        text2: card.node.deactivated_desciption,
        turned: false,
        date: card.node.cardTitle,
        icon: card.node.icon,
        buttons: {
          cta: card.node.cta,
          size: 'alone',
          color: 'BlancoNovedades',
          ctaText: card.node.cta_text,
        },
      })),
    };

    const titleHome = {
      title: this.props.data.allNovedadesTitleBlock.edges[0].node.title,
      titleTag: 'H1',
      subtitle: this.props.data.allNovedadesTitleBlock.edges[0].node.description,
      subtitleTag: 'H2',
      imgDesktop: this.props.data.allNovedadesImageHeader.edges[0].node.localImage.childImageSharp
        .fluid,
      imgMobile: this.props.data.allNovedadesImageMobileBlock.edges[0].node.localImage
        .childImageSharp.fluid,
      mobile_title: this.props.data.allNovedadesTitleBlock.edges[0].node.title,
      mobile_subtitle: this.props.data.allNovedadesTitleBlock.edges[0].node.description,
    };

    const settings = {
      mobileFirst: true,
      centerMode: false,
      infinite: false,
      centerPadding: '60px',
      slidesToShow: 3.7,
      speed: 500,
      dots: true,
      responsive: [
        {
          breakpoint: 425,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 459,
          settings: {
            slidesToShow: 1.2,
          },
        },
        {
          breakpoint: 505,
          settings: {
            slidesToShow: 1.3,
          },
        },
        {
          breakpoint: 539,
          settings: {
            slidesToShow: 1.4,
          },
        },
        {
          breakpoint: 569,
          settings: {
            slidesToShow: 1.5,
          },
        },
        {
          breakpoint: 611,
          settings: {
            slidesToShow: 1.6,
          },
        },
        {
          breakpoint: 689,
          settings: {
            slidesToShow: 1.7,
          },
        },
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 1.9,
          },
        },
        {
          breakpoint: 827,
          settings: {
            slidesToShow: 2.1,
          },
        },
        {
          breakpoint: 908,
          settings: {
            slidesToShow: 2.2,
          },
        },
        {
          breakpoint: 967,
          settings: {
            slidesToShow: 2.4,
          },
        },
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 2.6,
          },
        },
      ],
    };

    return (
      <Layout
        layoutFromOld
        locale={this.props.pageContext.locale}
        pageName={this.props.pageContext.pageName}
      >
        <SEO
          title={this.props.data.allNovedadesSeoData.edges[0].node._0.title}
          description={this.props.data.allNovedadesSeoData.edges[0].node._1.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
          imageRich={this.props.data.allNovedadesRichData.edges[0].node.rich_markup.imgRich}
        />
        <div className="is-fullWidth">
          <Hod
            data={this.props.data.allNovedadesRichData.edges[0].node.rich_markup}
            url={this.props.pageContext.url}
            img={this.props.data.allNovedadesRichData.edges[0].node.rich_markup.imgRich}
            hardcoded={
              this.props.data.allNovedadesSeoHardcoded.edges.length &&
              this.props.data.allNovedadesSeoHardcoded.edges[0].node.richmark_text
            }
          />
          <div className="novedades-header">
            <Heimdall promoData={titleHome} />
          </div>
          <div className="general-index general-aniversario">
            <div className="novedades-index">
              <NeoAesir
                locale={this.props.pageContext.locale}
                pageName={this.props.pageContext.pageName}
                title={this.props.data.allNovedadesBreadcrumbBlock.edges[0].node.name}
              />
              <H3 data={h1Data} />
              <div className="card-content-desktop">{this.cardMap(NovedadesData.cards)}</div>
              <div className="card-content-mobile">
                <Slider {...settings}>{this.cardMap(NovedadesData.cards)}</Slider>
              </div>
              <div className="promotions-content-promo">
                <YmirHeader
                  title={ymirData.title}
                  subtitle={ymirData.subtitle}
                  subtitle2={ymirData.subtitle2}
                />
                <Ymir data={ymirData} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Novedades;
