import './NovedadesCards.scss';

import React from 'react';
// import Img from 'gatsby-image/withIEPolyfill';
import Buttons from '../Buttons/Buttons';

const NovedadesCards = props => {
  const {
    data: {
      image,
      imageMobile,
      altImage,
      CardSize,
      text1,
      position,
      date,
      buttons: { cta, size, color, ctaText },
    },
  } = props;

  let SizeCard;
  SizeCard = CardSize;

  // if (parseInt(position) === 1) {
  //   SizeCard = 'fullheigth';
  // } else {
  //   SizeCard = CardSize;
  // }

  return (
    <div className={`cardNovedades ${SizeCard}`}>
      {date ? <div className="card-date">{date}</div> : null}
      <img className="card-img" src={image.src} alt={altImage} />
      <img
        className="card-img-mobile"
        src={imageMobile ? imageMobile.src : image.src}
        alt={altImage}
      />
      <div className="card-info-content">
        <div className="card-text2" dangerouslySetInnerHTML={{ __html: text1 }} />
        <div className="card-button">
          {cta ? (
            <Buttons color={color} size={size} link={cta} text={ctaText} />
          ) : (
            <button className="button-card" disabled>
              {' '}
              {ctaText}{' '}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
export default NovedadesCards;
