/* eslint-disable react/no-danger */
import { Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import Pawlink from '../PawLink/PawLink';
import './heimdallhome.scss';

const HeimdallHome = ({ promoData }) => {
  const [textClass, settextClass] = useState('is-image');
  const [videotextClass, setvideotextClass] = useState('img-grow');

  useEffect(() => {
    if (promoData.textAlign && promoData.videotextAlignAlt) {
      settextClass(`is-image ${promoData.textAlign} ${promoData.textAlignAlt}`);
      setvideotextClass(`img-grow ${promoData.videotextAlign} ${promoData.videotextAlignAlt}`);
    } else if (promoData.textAlign && !promoData.videotextAlignAlt) {
      settextClass(`is-image ${promoData.textAlign}`);
      setvideotextClass(`img-grow ${promoData.videotextAlign}`);
    } else if (!promoData.textAlign && promoData.videotextAlignAlt) {
      settextClass(`is-image ${promoData.textAlignAlt}`);
      setvideotextClass(`img-grow ${promoData.videotextAlignAlt}`);
    }
  }, []);

  let subtitulo;
  if (promoData.subtitleTag) {
    subtitulo = (
      <h2
        className="subtitle-heimdallHome"
        dangerouslySetInnerHTML={{ __html: promoData.subtitle }}
      />
    );
  } else {
    subtitulo = (
      <div
        className="subtitle-heimdallHome"
        dangerouslySetInnerHTML={{ __html: promoData.subtitle }}
      />
    );
  }
  const isMobile = (
    <BackgroundImage
      fluid={promoData.imgMobile}
      backgroundColor="#040e18"
      className="img-heimdallHome-mobile"
      style={{ height: `calc(100vw * 1/${promoData.imgMobile.aspectRatio})` }}
      alt="jumbo-image"
    >
      <div className={textClass}>
        <div className="text-heimdallHome">
          {promoData.titleTag ? (
            <h1
              className="title-heimdallHome"
              dangerouslySetInnerHTML={{ __html: promoData.mobile_title }}
            />
          ) : (
            <div
              className="title-heimdallHome"
              dangerouslySetInnerHTML={{ __html: promoData.mobile_title }}
            />
          )}

          {promoData.mobile_subtitle && (
            <div
              className="subtitle-heimdallHome"
              dangerouslySetInnerHTML={{ __html: promoData.mobile_subtitle }}
            />
          )}
          <div className="button-container">
            {promoData.mobile_cta_path_one && (
              <Pawlink
                type="anchor"
                destination={promoData.mobile_cta_path_one}
                className={`button is-rounded link-heimdallHome `}
              >
                {promoData.mobile_cta_copy_one}
              </Pawlink>
            )}

            {promoData.mobile_cta_copy_two && (
              <Link
                to={promoData.mobile_cta_path_two}
                className="button is-rounded link-heimdallHome Blancotransparente"
              >
                {promoData.mobile_cta_copy_two}
              </Link>
            )}
          </div>
        </div>
      </div>
    </BackgroundImage>
  );

  const isDesktop = (
    <BackgroundImage
      fluid={promoData.imgDesktop}
      backgroundColor="#040e18"
      className="img-heimdallHome-desktop"
      style={{ height: `calc(100vw * 1/${promoData.imgDesktop.aspectRatio})` }}
      alt="jumbo-image"
    >
      <div className={textClass}>
        <div className="text-heimdallHome">
          {promoData.titleTag ? (
            <h1
              className="title-heimdallHome"
              dangerouslySetInnerHTML={{ __html: promoData.mobile_title }}
            />
          ) : (
            <div
              className="title-heimdallHome"
              dangerouslySetInnerHTML={{ __html: promoData.mobile_title }}
            />
          )}
          {promoData.subtitle && subtitulo}

          <div
            className={`button-container ${promoData.ctaVideoDirection}_${promoData.cta2VideoDirection}`}
          >
            {promoData.ctaText && (
              <Pawlink
                type="anchor"
                destination={promoData.cta}
                className={`button is-rounded ${promoData.buttonImageType1}`}
              >
                {promoData.ctaText}
              </Pawlink>
            )}
            {promoData.ctaText2 && (
              <Pawlink
                type="anchor"
                destination={promoData.cta2}
                className={`button is-rounded ${promoData.buttonImageType1}`}
              >
                {promoData.ctaText2}
              </Pawlink>
            )}
          </div>
        </div>
      </div>
    </BackgroundImage>
  );

  const isVideo = (
    <>
      <div className={videotextClass}>
        <div className="text-promo-panel">
          {promoData.video_title ? (
            <div
              className="title-promo-panel"
              dangerouslySetInnerHTML={{ __html: promoData.video_title }}
            />
          ) : null}
          {promoData.video_subtitle ? (
            <div
              className="subtitle-promo-panel"
              dangerouslySetInnerHTML={{ __html: promoData.video_subtitle }}
            />
          ) : null}
          <div
            className={`button-container ${promoData.ctaVideoDirection}_${promoData.cta2VideoDirection}`}
          >
            {promoData.ctaVideo ? (
              <Pawlink
                type="unknown"
                destination={promoData.ctaVideo}
                className={`button is-rounded ${promoData.buttonVideoType1}`}
              >
                {promoData.ctaVideoText}
              </Pawlink>
            ) : null}
            {promoData.ctaVideo2 && (
              <Pawlink
                type="anchor"
                destination={promoData.ctaVideo2}
                className={`button is-rounded ${promoData.buttonVideoType2}`}
              >
                {promoData.ctaVideoText2}
              </Pawlink>
            )}
          </div>
        </div>
      </div>
      <div className="player-wrapper">
        <ReactPlayer
          className="react-player"
          url={promoData.video}
          playing
          forceVideo
          width="100%"
          height="auto"
          preload
          volume={1}
          muted
          loop
          controls={false}
        />
      </div>
    </>
  );

  return (
    <div className="HeimdalHome-container">
      <div className="HeimdalHome-desktop">{promoData.video ? isVideo : isDesktop}</div>
      <div className="HeimdalHome-mobile">{isMobile}</div>
    </div>
  );
};

export default HeimdallHome;
